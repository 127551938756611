import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 5×2\\@90% 1RM`}</p>
    <p>{`Partner Assisted Glute Ham Raise or DB Stiff Leg Deadlifts 5×2`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Support Your Local Box Workout 1`}</em></strong></p>
    <p>{`10:00 amrap of:`}</p>
    <p>{`10-Squats`}</p>
    <p>{`9-Right Arm DB Snatch (50/35)`}</p>
    <p>{`10-Pushups`}</p>
    <p>{`9-Left Arm DB Snatch`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Sign up for the Support Your Local Box at home competition today
at: `}</em></strong><a parentName="p" {...{
        "href": "https://games.crossfit.com"
      }}><strong parentName="a"><em parentName="strong">{`https://games.crossfit.com`}</em></strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      